import React, { useCallback, useEffect, useRef, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import { get } from '../../../../services/http/http.service';
import { LoaderComponent } from 'react-fullscreen-loader';
import { getLocalDate } from '../../../../services/date/date';
import { getDteType, getTipoDocumento } from '../../../../utils/dte/dte.util';
import TablaTotalesFactura from './components/TablaTotalesFactura';
import ModalReporte from '../../dte/components/ModalReporte';
import TablaTotalesCreditoFiscal from './components/TablaTotalesCreditoFiscal';
import TablaTotalesNotaCredito from './components/TablaTotalesNotaCredito';
import TablaTotalesSujetoExcluido from './components/TablaTotalesSujetoExcluido';
import { getSession } from '../../../../services/encrytion/encryption.service';
import CardWrapperTools from '../../../../Components/Common/Cards/CardWrapperTools';
import Anulacion from './components/Anulacion';
import { obtenerFechaLimite } from './utils/consulta.util';
import { FormProvider, useForm } from 'react-hook-form';
import ModalDetalleDte from './components/modales/ModalDetalleDte';
import ModalReenviarDte from './components/modales/ModalReenviarDte';
import { Alert, loadingReload } from '../../../../services/alerts/alerts';
import * as slug from 'slug';
import { saveAs } from 'file-saver';
import TablaTotalesRecibo from './components/TablaTotalesRecibo';

const ConsultaDetalle = () => {
  const formOptions = {
    defaultValues: {
      reload: false,
    },
  };

  const methods = useForm(formOptions);
  const modalReporteRef = useRef(null);
  const modalReenvioRef = useRef(null);
  const modalAnulacionRef = useRef(null);
  const modalDetalleRef = useRef(null);
  const { codigoGeneracion, clienteId } = useParams();
  const [dte, setDte] = useState(null);
  const [notaCreditoRelacionada, setNotaCreditoRelacionada] = useState(null);
  const [notaDebitoRelacionada, setNotaDebitoRelacionada] = useState(null);

  const [loading, setLoading] = useState(false);
  const [datosVencimientoAnulacion, setdatosVencimientoAnulacion] =
    useState(null);
  const navigate = useNavigate();

  const getDteById = useCallback(async () => {
    try {
      setLoading(true);
      setNotaCreditoRelacionada(null);
      const { data } = await get(
        `dte/codigo-generacion/${codigoGeneracion}/cliente-id/${clienteId}`,
      );

      if (data?.en_nota_credito) {
        const { data: notaCredito } = await get(
          `dte/codigo-generacion/${data?.nota_credito_id}/cliente-id/${clienteId}`,
        );
        setNotaCreditoRelacionada(notaCredito);
      }

      if (data?.en_nota_debito) {
        const { data: notaDebito } = await get(
          `dte/codigo-generacion/${data?.nota_debito_id}/cliente-id/${clienteId}`,
        );
        setNotaDebitoRelacionada(notaDebito);
      }
      setDte(data);
      setdatosVencimientoAnulacion(obtenerFechaLimite(data));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(
        'Se ha producido un error al momento de obtener los clientes!',
        {
          variant: 'error',
          preventDuplicate: true,
        },
      );
    }
  }, [codigoGeneracion, clienteId]);

  useEffect(() => {
    async function fetchData() {
      await getDteById();
    }
    fetchData();
  }, [getDteById]);

  const handleObtenerReporte = (clienteId, tipoDocumento, codigoGeneracion) => {
    modalReporteRef.current.generarReporte(
      clienteId,
      tipoDocumento,
      codigoGeneracion,
    );
  };

  const handleNavigation = () => {
    const queryParams = getSession('queryParamsConsulta');
    // Convierte el objeto queryParams en una cadena de consulta
    let queryString;

    if (queryParams) {
      queryString = Object.keys(queryParams)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(
              queryParams[key],
            )}`,
        )
        .join('&');
    }

    // Agrega la cadena de consulta a la URL
    const newUrl = queryParams
      ? `/dte/consulta?${queryString}`
      : '/dte/consulta';

    // Navega a la nueva URL
    navigate(newUrl);
  };

  useEffect(() => {
    async function fetchData() {
      if (methods.watch('reload_detalle') === true) {
        methods.setValue('reload_detalle', false);
        await getDteById();
      }
    }
    fetchData();
  }, [getDteById, methods.formState, methods]);

  const handleDownloadJson = async () => {
    try {
      loadingReload(true, 'Descargando JSON');

      const { data } = await get(
        `dte/json/${dte.codigo_generacion}/cliente-id/${clienteId}`,
      );
      const response = await fetch(data);
      const fileBlob = await response.blob();
      saveAs(
        fileBlob,
        `${slug(getDteType(dte?.datos_dte?.identificacion?.tipoDte))}-${
          dte?.codigo_generacion
        }.json`,
        {
          autoBom: true,
        },
      );

      loadingReload(false);
    } catch (error) {
      Alert({
        title: 'Error',
        text: 'No se pudo descargar el JSON',
        icon: 'error',
      });
      loadingReload(false);
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <LoaderComponent loading={loading} loadingColor="blue" />

        {!loading ? (
          <div>
            <div className="card mb-3">
              <div className="card-body">
                <div className="row align-items-center text-center">
                  <div className="col-12 text-center mt-sm-0">
                    <h2>
                      {getDteType(dte?.datos_dte?.identificacion?.tipoDte)}
                    </h2>

                    {dte?.invalidado ? (
                      <h2>
                        <span className="badge badge-subtle-danger fs-2">
                          <span>Documento invalidado</span>
                        </span>
                      </h2>
                    ) : null}
                    <h5>{dte?.datos_dte?.emisor?.nombre}</h5>
                    <p className="fs-0 mb-0">
                      {dte?.datos_dte?.emisor?.direccion.complemento}
                    </p>
                  </div>
                  <div className="col-12">
                    <hr />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <>
                      <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            id="home-tab"
                            data-bs-toggle="tab"
                            href="#datosTransmision"
                            role="tab"
                            aria-controls="datosTransmision"
                            aria-selected="true"
                          >
                            Información de transmisión
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            id="profile-tab"
                            data-bs-toggle="tab"
                            href="#datosReceptor"
                            role="tab"
                            aria-controls="datosReceptor"
                            aria-selected="false"
                          >
                            Información de receptor:{' '}
                            {dte?.datos_dte?.receptor?.nombre?.toUpperCase()}
                          </a>
                        </li>
                      </ul>

                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="datosTransmision"
                          role="tabpanel"
                          aria-labelledby="datosTransmision"
                        >
                          <CardWrapperTools
                            title="Información de transmisión"
                            tools={
                              <div className="dropdown">
                                <button
                                  className="btn btn-sm btn-falcon-default dropdown-toggle dropdown-caret-none"
                                  type="button"
                                  id="ticket-layout"
                                  data-bs-toggle="dropdown"
                                >
                                  <span className="me-1">Acciones</span>
                                  <span className="fas fa-chevron-down"></span>
                                </button>
                                <div
                                  className="dropdown-menu dropdown-toggle-item dropdown-menu-end border py-2"
                                  aria-labelledby="ticket-layout"
                                >
                                  {dte?.respuesta_mh?.selloRecibido ? (
                                    <>
                                      <button
                                        className="dropdown-item"
                                        type="button"
                                        onClick={() => {
                                          handleDownloadJson();
                                        }}
                                      >
                                        Descargar JSON
                                      </button>

                                      <button
                                        className="dropdown-item"
                                        type="button"
                                        onClick={() => {
                                          handleObtenerReporte(
                                            clienteId,
                                            getDteType(
                                              dte?.datos_dte?.identificacion
                                                ?.tipoDte,
                                            ),
                                            dte?.codigo_generacion,
                                          );
                                        }}
                                      >
                                        Generar PDF
                                      </button>
                                      <button
                                        className="dropdown-item"
                                        type="button"
                                        onClick={() => {
                                          modalReenvioRef.current.openModal();
                                        }}
                                      >
                                        Reenviar DTE
                                      </button>
                                      <div class="dropdown-divider"></div>
                                      {dte?.respuesta_mh?.selloRecibido &&
                                      datosVencimientoAnulacion !== null &&
                                      datosVencimientoAnulacion?.vencida ===
                                        false ? (
                                        <Anulacion
                                          dte={dte}
                                          refModal={modalAnulacionRef}
                                          refModalDetalle={modalDetalleRef}
                                          notaCredito={
                                            notaCreditoRelacionada?.invalidado
                                          }
                                          notaDebito={
                                            notaDebitoRelacionada?.invalidado
                                          }
                                          invalidado={dte?.invalidado}
                                        />
                                      ) : null}
                                    </>
                                  ) : null}

                                  <ModalDetalleDte
                                    codigoGeneracion={dte?.codigo_generacion}
                                    ambiente={
                                      dte?.datos_dte?.identificacion?.ambiente
                                    }
                                    sucursal={dte?.sucursal}
                                    id={dte?._id}
                                  />
                                </div>
                              </div>
                            }
                          >
                            <div className="row g-3">
                              <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                                <label htmlFor="">Número de control:</label>

                                <input
                                  type="text"
                                  className="form-control"
                                  value={dte?.numero_control}
                                  disabled
                                />
                              </div>

                              <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                                <label htmlFor="">Código de Generación:</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={dte?.codigo_generacion}
                                  disabled
                                />
                              </div>

                              <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                                <label htmlFor="">Sello de Recepción:</label>

                                <input
                                  type="text"
                                  className="form-control"
                                  value={
                                    dte?.respuesta_mh?.selloRecibido
                                      ? dte?.respuesta_mh?.selloRecibido
                                      : 'Pendiente'
                                  }
                                  disabled
                                />
                              </div>

                              <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                                <label htmlFor="">Fecha de generación:</label>

                                <input
                                  type="text"
                                  className="form-control"
                                  value={getLocalDate(
                                    dte?.created_at,
                                    'dddd DD MMMM YYYY, h:mm:ss a',
                                  )}
                                  disabled
                                />
                              </div>

                              <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                                <label htmlFor="">Ambiente:</label>
                                <br />

                                <div className="text-center d-grid gap-2">
                                  {dte?.datos_dte?.identificacion?.ambiente ===
                                  '01' ? (
                                    <span className="badge rounded-pill badge-subtle-success fs-0 text-center">
                                      <span
                                        className="fas fa-check me-1"
                                        data-fa-transform="shrink-4"
                                      />
                                      <span>PRODUCCIÓN</span>
                                    </span>
                                  ) : (
                                    <span className="badge badge-subtle-danger fs-0 text-center">
                                      <span>PRUEBAS</span>
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                                <label htmlFor="">Estado de transmisión:</label>
                                <br />

                                <div className="text-center d-grid gap-2">
                                  {dte?.estado_envio === 'ENVIADO' ||
                                  dte?.estado_envio ===
                                    'ENVIADO EN CONTINGENCIA' ? (
                                    <span className="badge badge-subtle-success  me-1 mb-2 fs-0">
                                      <span
                                        className="fas fa-check me-1"
                                        data-fa-transform="shrink-4"
                                      />
                                      <span>{dte?.estado_envio}</span>
                                    </span>
                                  ) : dte?.estado_envio === 'PENDIENTE' ? (
                                    <span className="badge badge-subtle-warning  me-1 mb-2 fs-0">
                                      <span
                                        className="fas fa-check me-1"
                                        data-fa-transform="shrink-4"
                                      />
                                      <span>{dte?.estado_envio}</span>
                                    </span>
                                  ) : (
                                    <span className="badge badge-subtle-danger  me-1 mb-2 fs-0">
                                      <span
                                        className="fas fa-check me-1"
                                        data-fa-transform="shrink-4"
                                      />
                                      <span>{dte?.estado_envio}</span>
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                                <label htmlFor="">Estado de Recepción:</label>
                                <br />

                                <div className="text-center d-grid gap-2">
                                  {dte?.respuesta_mh?.estado === 'PROCESADO' ? (
                                    <span className="badge badge-subtle-success  me-1 mb-2 fs-0">
                                      <span
                                        className="fas fa-check me-1"
                                        data-fa-transform="shrink-4"
                                      />
                                      <span>{dte?.respuesta_mh?.estado}</span>
                                    </span>
                                  ) : (
                                    <span className="badge badge-subtle-warning  me-1 mb-2 fs-0">
                                      <span
                                        className="fas fa-check me-1"
                                        data-fa-transform="shrink-4"
                                      />
                                      <span>
                                        {dte?.respuesta_mh?.estado ??
                                          'PENDIENTE'}
                                      </span>
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                                <label htmlFor="">Total:</label>
                                <br />

                                <div className="text-center d-grid gap-2">
                                  <span className="badge badge-subtle-primary fw-bold fs-0">
                                    $
                                    {dte?.datos_dte?.resumen?.totalPagar
                                      ? parseFloat(
                                          dte?.datos_dte?.resumen?.totalPagar,
                                        ).toFixed(2)
                                      : parseFloat(
                                          dte?.datos_dte?.resumen
                                            ?.montoTotalOperacion,
                                        ).toFixed(2)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </CardWrapperTools>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="datosReceptor"
                          role="tabpanel"
                          aria-labelledby="datosReceptor"
                        >
                          <CardWrapperTools title="Datos Receptor">
                            <div className="row g-3">
                              <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                                <label htmlFor="">Cliente:</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={
                                    dte?.datos_dte?.receptor?.nombre ||
                                    dte?.datos_dte?.sujetoExcluido?.nombre ||
                                    'Cliente General'
                                  }
                                  disabled
                                />
                              </div>

                              <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                                <label htmlFor="">Correo electrónico:</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={
                                    dte?.datos_dte?.receptor?.correo ||
                                    dte?.datos_dte?.sujetoExcluido?.correo
                                  }
                                  disabled
                                />
                              </div>

                              <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                                <label htmlFor="">Teléfono:</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={
                                    dte?.datos_dte?.receptor?.telefono ||
                                    dte?.datos_dte?.sujetoExcluido?.telefono
                                  }
                                  disabled
                                />
                              </div>

                              {dte?.datos_dte?.receptor?.tipoDocumento ||
                              dte?.datos_dte?.sujetoExcluido?.tipoDocumento ? (
                                <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                                  <label htmlFor="">Tipo de Documento:</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={
                                      dte?.datos_dte?.receptor?.tipoDocumento
                                        ? getTipoDocumento(
                                            dte?.datos_dte?.receptor
                                              ?.tipoDocumento,
                                          )
                                        : getTipoDocumento(
                                            dte?.datos_dte?.sujetoExcluido
                                              ?.tipoDocumento,
                                          )
                                    }
                                    disabled
                                  />
                                </div>
                              ) : null}

                              {dte?.datos_dte?.receptor?.numDocumento ||
                              dte?.datos_dte?.sujetoExcluido?.numDocumento ? (
                                <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                                  <label htmlFor="">Número de Documento:</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={
                                      dte?.datos_dte?.receptor?.numDocumento ||
                                      dte?.datos_dte?.sujetoExcluido
                                        ?.numDocumento
                                    }
                                    disabled
                                  />
                                </div>
                              ) : null}

                              {dte?.datos_dte?.receptor?.nit ? (
                                <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                                  <label htmlFor="">NIT:</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={dte?.datos_dte?.receptor?.nit}
                                    disabled
                                  />
                                </div>
                              ) : null}
                              {dte?.datos_dte?.receptor?.nrc ? (
                                <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                                  <label htmlFor="">NRC:</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={dte?.datos_dte?.receptor?.nrc}
                                    disabled
                                  />
                                </div>
                              ) : null}

                              <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                                <label htmlFor="">Dirección:</label>
                                <textarea
                                  rows={
                                    dte?.datos_dte?.receptor?.direccion
                                      ?.complemento?.length > 25
                                      ? 3
                                      : 1
                                  }
                                  type="text"
                                  className="form-control"
                                  value={
                                    dte?.datos_dte?.receptor?.direccion
                                      ?.complemento ||
                                    dte?.datos_dte?.sujetoExcluido?.direccion
                                      ?.complemento
                                  }
                                  disabled
                                />
                              </div>
                              {dte?.datos_dte?.receptor?.nrc ? (
                                <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                                  <label htmlFor="">
                                    Actividad Económica::
                                  </label>
                                  <textarea
                                    type="text"
                                    rows={
                                      dte?.datos_dte?.receptor?.descActividad
                                        ?.length > 25
                                        ? 3
                                        : 1
                                    }
                                    className="form-control"
                                    value={
                                      dte?.datos_dte?.receptor?.descActividad
                                    }
                                    disabled
                                  />
                                </div>
                              ) : null}
                            </div>
                          </CardWrapperTools>
                        </div>
                      </div>
                    </>
                  </div>
                </div>
                <div className="row">
                  {datosVencimientoAnulacion !== null && !dte?.invalidado ? (
                    datosVencimientoAnulacion?.vencida ? (
                      <div className="col-12">
                        <div className="alert alert-danger" role="alert">
                          <p>
                            <strong>Atención:</strong> La invalidación no está
                            disponible para este documento, el plazo para
                            realizar la invalidación ha vencido el{' '}
                            <strong>
                              {datosVencimientoAnulacion?.fechaLimite}
                            </strong>
                            .
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="col-12">
                        <div className="alert alert-primary" role="alert">
                          <p>
                            <strong>Atención:</strong> La invalidación está
                            disponible para este documento, pero el plazo para
                            realizar la invalidación es vigente hasta el{' '}
                            <strong>
                              {datosVencimientoAnulacion?.fechaLimite}
                            </strong>
                            .
                          </p>
                        </div>
                      </div>
                    )
                  ) : null}

                  {notaCreditoRelacionada?.invalidado === false ? (
                    <div className="col-12">
                      <div className="alert alert-danger" role="alert">
                        <p>
                          <strong>Advertencia:</strong> El crédito fiscal
                          seleccionado esta relacionado a la nota crédito{' '}
                          <strong>
                            {notaCreditoRelacionada?.codigo_generacion}
                          </strong>{' '}
                          que no ha sido invalidada. Debe invalidar la nota de
                          crédito para poder invalidar este documento.
                          <button
                            type="button"
                            className="btn btn-link text-danger"
                            onClick={() => {
                              navigate(
                                `/dte/consulta/${notaCreditoRelacionada?.codigo_generacion}/detalle/${clienteId}`,
                              );
                            }}
                          >
                            Invalidar nota de crédito
                          </button>
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {notaDebitoRelacionada?.invalidado === false ? (
                    <div className="col-12">
                      <div className="alert alert-danger" role="alert">
                        <p>
                          <strong>Advertencia:</strong> El crédito fiscal
                          seleccionado esta relacionado a la nota de débito{' '}
                          <strong>
                            {notaDebitoRelacionada?.codigo_generacion}
                          </strong>{' '}
                          que no ha sido invalidada. Debe invalidar la nota de
                          débito para poder invalidar este documento.
                          <button
                            type="button"
                            className="btn btn-link text-danger"
                            onClick={() => {
                              navigate(
                                `/dte/consulta/${notaDebitoRelacionada?.codigo_generacion}/detalle/${clienteId}`,
                              );
                            }}
                          >
                            Invalidar nota de débito
                          </button>
                        </p>
                      </div>
                    </div>
                  ) : null}
                </div>

                {/*Aqui va tabla */}

                {dte?.datos_dte?.identificacion?.tipoDte === '01' ? (
                  <TablaTotalesFactura data={dte} />
                ) : null}

                {dte?.datos_dte?.identificacion?.tipoDte === '00' ? (
                  <TablaTotalesRecibo data={dte} />
                ) : null}

                {dte?.datos_dte?.identificacion?.tipoDte === '03' ? (
                  <TablaTotalesCreditoFiscal data={dte} />
                ) : null}

                {dte?.datos_dte?.identificacion?.tipoDte === '05' ? (
                  <TablaTotalesNotaCredito data={dte} />
                ) : null}

                {dte?.datos_dte?.identificacion?.tipoDte === '14' ? (
                  <TablaTotalesSujetoExcluido data={dte} />
                ) : null}
              </div>

              <div className="card-footer text-center">
                <button
                  className="btn btn-outline-primary btn-lg"
                  type="button"
                  onClick={handleNavigation}
                >
                  <span className="fas far fa-arrow-alt-circle-left me-1" />
                  SALIR
                </button>
              </div>
            </div>
          </div>
        ) : null}
        <ModalReporte ref={modalReporteRef} />
        <ModalReenviarDte ref={modalReenvioRef} dte={dte} />
      </FormProvider>
    </>
  );
};

export default ConsultaDetalle;
