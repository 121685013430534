import React from 'react';

const InformationSystem = () => {
  return (
    <div className="col-md-5 text-center bg-card-gradient">
      <div
        className="position-relative p-4 pt-md-5 pb-md-7"
        data-bs-theme="light"
      >
        <div
          className="bg-holder bg-auth-card-shape"
          style={{
            backgroundImage:
              'url(../../../assets/img/icons/spot-illustrations/half-circle.png)',
          }}
        ></div>
        <div className="z-1 position-relative">
          <img
            className="me-2"
            src="https://res.cloudinary.com/dsyq7zx0p/image/upload/v1735413944/positivo_login_cfc9s0.png"
            alt="..."
            style={{ maxWidth: '100%', height: 'auto' }}
          />

          <p className="opacity-75 text-white">
            Sistema de transmisión de Documentos Tributarios Electrónicos (DTE)
            a través del servicio Electrónico del Ministerio de Hacienda.
          </p>
        </div>
      </div>
    </div>
  );
};

export default InformationSystem;
