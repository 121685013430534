import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { TableFilterV2Services } from '../../Components/Common/Tables/TableFilterV2.service';
import { getSession } from '../../services/encrytion/encryption.service';
import { CardWrapper } from '../../Components/Common/Cards';
import {
  AlertQuestionYesNo,
  loadingReload,
} from '../../services/alerts/alerts';
import { patch } from '../../services/http/http.service';
export default function MisClientes() {
  const navigate = useNavigate();
  const tablaRef = useRef();

  const clienteSeleccionado = getSession('clienteSeleccionado');

  const columns = [
    {
      name: 'Nombre o Razón Social',
      selector: (row) => row.nombre_cliente,
    },
    {
      name: 'Nombre Comercial',
      selector: (row) => row.nombre_comercial,
    },
    {
      name: 'NIT',
      selector: (row) => row.nit ?? row.numero_documento ?? 'N/A',
    },
    {
      name: 'NRC',
      selector: (row) => (row.nrc ? row.nrc : 'N/A'),
    },
    {
      name: 'Correo',
      selector: (row) => (row.correo ? row.correo : 'N/A'),
    },

    {
      name: 'Contribuyente',
      selector: (row) =>
        row.contribuyente ? (
          <span className="badge rounded-pill badge-subtle-success">Si</span>
        ) : (
          <span className="badge rounded-pill badge-subtle-danger">No</span>
        ),
    },
    {
      name: 'Activo',
      selector: (row) =>
        row.activo ? (
          <span className="badge rounded-pill badge-subtle-primary">
            Activo
          </span>
        ) : (
          <span className="badge rounded-pill badge-subtle-warning">
            Inactivo
          </span>
        ),
    },
    {
      name: 'Acciones',
      selector: (row) => (
        <>
          <button
            className="btn btn-link p-0"
            type="button"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Edit"
            onClick={() => handleEdit(row?._id)}
          >
            <span className="text-500 fas fa-edit"></span>
          </button>
          <button
            className="btn btn-link p-0 ms-2"
            type="button"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Delete"
            onClick={() => handleDelete(row?._id, row.activo)}
          >
            <span className="text-500 fas fa-trash-alt"></span>
          </button>
        </>
      ),
    },
  ];
  const handleEdit = (id) => navigate(`/mis-clientes/update/${id}`);

  async function handleDelete(id, activo) {
    const textoActivar = !activo ? 'activar' : 'desactivar';
    const confirm = await AlertQuestionYesNo({
      title: `¿Está seguro que desea ${textoActivar} este usuario?`,
    });
    if (confirm) {
      loadingReload(true);

      const response = {
        id: id,
        activo: !activo,
      };
      await patch('mis-clientes/status', response);
      loadingReload(false);
      tablaRef.current.refreshData();
    }
  }
  return (
    <CardWrapper title="Listado de mis clientes" urlForm="/mis-clientes/create">
      <TableFilterV2Services
        columns={columns}
        endpoint={`mis-clientes?params=${clienteSeleccionado.id}`}
        ref={tablaRef}
      />
    </CardWrapper>
  );
}
