/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { enqueueSnackbar } from 'notistack';

import { useNavigate, useParams } from 'react-router-dom';

import CardWrapperTools from '../../Components/Common/Cards/CardWrapperTools';
import { getSession } from '../../services/encrytion/encryption.service';
import { get, patch, post } from '../../services/http/http.service';
import { Alert, loadingReload } from '../../services/alerts/alerts';
import { InputMask } from '@react-input/mask';

import { ERROR_TYPE_MESSAGE } from '../../config/constants';

export default function CreateMisClientes() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [cliente, setCliente] = useState([]);
  const [contribuyente, setContribuyente] = useState(false);
  const [porDefecto, setPorDefecto] = useState(false);
  const [actividadEconmica, setActividadEconmica] = useState([]);

  const [municipioOptions, setMunicipioOptions] = useState([]);
  const [maskNumeroReceptor, setMaskNumeroReceptor] = useState(0); //0-other, 1- DUI, 2-NIT
  const [maskNumeroNit, setMaskNumeroNit] = useState(0); //0-other, 1- DUI, 2-NIT

  const [empresa, ,] = useState([
    {
      value: '1',
      label: 'Micro empresa',
    },
    {
      value: '2',
      label: 'Pequeña empresa',
    },
    {
      value: '3',
      label: 'Mediana empresa',
    },
    {
      value: '4',
      label: 'Grande empresa',
    },
  ]);

  useEffect(() => {
    const cliente = getSession('clienteSeleccionado');

    setCliente(cliente);
    if (id) {
      loadUserClienteById(id);
    }
  }, []);

  const validationSchema = Yup.object().shape({
    contribuyente: Yup.boolean(),
    nit: Yup.string().when('contribuyente', {
      is: true,
      then: () =>
        Yup.string()
          .required('NIT es requerido')
          .test('len', 'NIT es requerido', (val) => {
            const lengthExpected = maskNumeroNit === 1 ? 9 : 14;
            return (
              val?.replaceAll('-', '').replaceAll('_', '').length ===
              lengthExpected
            );
          }),
    }),

    tipo_documento: Yup.object().shape({
      label: Yup.string(),

      value: Yup.string(),
    }),
    numero_documento: Yup.string().when('contribuyente', {
      is: false,
      then: () =>
        Yup.string().test('len', 'Número de documento es requerido', (val) => {
          let lengthExpected = 0;
          if (maskNumeroReceptor === 1) lengthExpected = 9;
          else if (maskNumeroReceptor === 2) lengthExpected = 14;
          else return true;

          return (
            val?.replaceAll('-', '').replaceAll('_', '').length ===
            lengthExpected
          );
        }),
    }),

    nombre_cliente: Yup.string().when('contribuyente', {
      is: false,
      then: () => Yup.string().required('Nombre del cliente es requerido'),
    }),

    departamento: Yup.object().when('contribuyente', {
      is: true,
      then: () =>
        Yup.object().shape({
          label: Yup.string().required('Municipio es requerido'),

          value: Yup.string().required('Municipio es requerido'),
        }),
    }),

    municipios: Yup.object()
      .when('contribuyente', {
        is: true,
        then: () =>
          Yup.object().shape({
            label: Yup.string().required('Municipio es requerido'),

            value: Yup.string().required('Municipio es requerido'),
          }),
      })
      .when('departamento', {
        //evaluar si el campo departamento esta lleno
        is: (departamento) => departamento?.value,
        then: () =>
          Yup.object().shape({
            label: Yup.string().required('Municipio es requerido'),

            value: Yup.string().required('Municipio es requerido'),
          }),
      }),

    direccion: Yup.string()
      .when('contribuyente', {
        is: true,
        then: () => Yup.string().required('Dirección es requerida'),
      })
      .when('departamento', {
        //evaluar si el campo departamento esta lleno
        is: (departamento) => departamento?.value,
        then: () => Yup.string().required('Dirección es requerida'),
      })
      .when('municipios', {
        //evaluar si el campo departamento esta lleno
        is: (municipios) => municipios?.value,
        then: () =>
          Yup.string()
            .required('Dirección es requerida')
            .min(10, 'Dirección muy corta'),
      }),
    correo: Yup.string().when('contribuyente', {
      is: true,
      then: () =>
        Yup.string()
          .email('Correo electrónico no válido')
          .required('El correo es requerido'),
    }),

    telefono: Yup.string().when('contribuyente', {
      is: true,
      then: () => Yup.string().required('El Teléfono es requerido'),
    }),
    actividades_economicas: Yup.array().when('contribuyente', {
      is: true,
      then: () =>
        Yup.array(
          Yup.object({
            value: Yup.string(),
            label: Yup.string(),
          }),
        )

          .max(3, 'No puede haber mas de 3 actividades economicas')
          .min(1, 'Debe seleccionar al menos una actividad economica'),
    }),
    nrc: Yup.string().when('contribuyente', {
      is: true,
      then: () =>
        Yup.number()
          .required('NRC es requerido')
          .max(99999999, 'Máximo 8 caracteres'),
    }),

    tipo_empresa: Yup.object()
      .shape({
        label: Yup.string(),

        value: Yup.string(),
      })
      .when('contribuyente', {
        is: true,
        then: () => Yup.object().required('Tipo de empresa es requerido'),
      }),
    porDefecto: Yup.boolean(),
  });

  const {
    register,
    watch,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      actividades_economicas: [],
    },
  });

  const loadUserClienteById = async (id) => {
    if (id) {
      const { data } = await get(`/mis-clientes/${id}`);
      const tipoEmpresa = empresa.find(
        (item) => item.value === data.tipo_empresa,
      );

      if (data.contribuyente) {
        if (data.nit?.length <= 10) {
          setValue('tipo_documento_nit', {
            label: 'NIT (DUI Homologado)',
            value: '36.2',
          });
          configureInputMaskForNumeroNit('36.2');
        } else {
          setValue('tipo_documento_nit', {
            label: 'NIT',
            value: '36',
          });
          configureInputMaskForNumeroNit('36');
        }
      }

      setContribuyente(data.contribuyente);
      setValue('contribuyente', data.contribuyente);
      setValue('nit', data.nit);

      setValue('numero_documento', data.numero_documento);
      setValue(
        'tipo_documento',
        data.tipo_documento
          ? {
              code: data.tipo_documento.code,
              slug: data.tipo_documento.slug,
              value: data.tipo_documento.id,
              label: data.tipo_documento.value,
            }
          : undefined,
      );
      setValue('nrc', data.nrc);
      setValue('nombre_cliente', data.nombre_cliente);
      setValue('nombre_comercial', data.nombre_comercial);
      setValue('departamento', {
        code: data.departamento.code,
        slug: data.departamento.slug,
        value: data.departamento.id,
        label: data.departamento.value,
      });
      await fetchMunicipios(data.departamento.id);

      setValue('municipios', {
        code: data.municipio.code,
        slug: data.municipio.slug,
        value: data.municipio.id,
        label: data.municipio.value,
        departamentoId: data.municipio.departamentoId,
      });
      setValue('direccion', data.direccion);
      setValue('correo', data.correo);
      setValue(
        'actividades_economicas',
        data.actividad_economica
          ? data.actividad_economica.map((item) => ({
              value: item.id,
              label: item.value,
              code: item.code,
              slug: item.slug,
              default: item.default ?? false,
            }))
          : [],
      );
      setValue('telefono', data.telefono);
      setValue(
        'tipo_empresa',
        tipoEmpresa?.value
          ? {
              value: tipoEmpresa.value,
              label: tipoEmpresa.label,
            }
          : undefined,
      );
    }
  };

  const laodTipoDocumento = async () => {
    const { data } = await get(
      'generales/tipos-documentos-identificacion-receptor',
    );
    const options = data.map((item) => ({
      value: item.id,
      label: item.value,
      code: item.code,
      slug: item.slug,
    }));
    options.push({
      value: '36.2',
      label: 'NIT (DUI Homologado)',
      code: '36.2',
      slug: 'dui',
    });

    return options;
  };

  const loadDepartamento = async (inputValue) => {
    try {
      const { data } = await get(
        `generales/departamentos?search=${inputValue}`,
      );
      const options = data.map((item) => {
        return {
          slug: item?.slug,
          code: item.code,
          value: item?.id,
          label: item.value,
        };
      });
      return options;
    } catch (error) {
      Alert({
        title: 'Error',
        text: 'Error al cargar los departamentos',
        icon: 'error',
      });
    }
  };

  const fetchMunicipios = async (value) => {
    try {
      const { data } = await get(
        `generales/municipios?departamentoId=${value}`,
      );
      const options = data.map((item) => ({
        code: item.code,
        value: item.id,
        slug: item.slug,
        label: item.value,
        departamentoId: value,
      }));
      setMunicipioOptions(options);
    } catch (error) {
      Alert({
        title: 'Error',
        text: 'Error al cargar los municipios',
        icon: 'error',
      });
    }
  };

  const loadActividadEconomica = async (inputValue) => {
    try {
      const { data } = await get(
        `/generales/actividades-economicas?search=${inputValue}`,
      );
      const options = data.map((item) => ({
        value: item.id,
        label: item.value,
        code: item.code,
        slug: item.slug,
      }));
      return options;
    } catch (error) {
      Alert('error', 'Error', 'No se pudo cargar las actividades economicas');
    }
  };

  const omitEmpty = (obj) => {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      if (value !== undefined && value !== null && value !== '') {
        // Para arrays o objetos, aplicamos la misma lógica de forma recursiva
        if (typeof value === 'object' && !(value instanceof Array)) {
          value = omitEmpty(value);
        }
        // Para arrays, aplicamos la lógica solo si no están vacíos después de filtrar
        if (
          !(value instanceof Array) ||
          (value instanceof Array && value.length > 0)
        ) {
          acc[key] = value;
        }
      }
      return acc;
    }, {});
  };

  const sendCliente = async (datos) => {
    loadingReload(true, id ? 'Actualizando cliente' : 'Guardando cliente');
    try {
      let payload = {
        tipo_documento: datos.tipo_documento?.value
          ? {
              id: datos.tipo_documento.value,
              code: datos.tipo_documento.code,
              slug: datos.tipo_documento.slug,
              value: datos.tipo_documento.label,
            }
          : null,
        cliente_id: cliente.id,
        cliente: {
          nit: cliente.nit,
          nrc: cliente.nrc,
          correo: cliente.correo,
          nombre: cliente.nombre,
          nombre_comercial: cliente.nombreComercial,
          telefono: cliente.telefono,
        },
        numero_documento: datos.numero_documento,
        nombre_cliente: datos.nombre_cliente,
        nombre_comercial: datos?.nombre_comercial
          ? datos.nombre_comercial
          : datos.nombre_cliente,
        departamento: datos.departamento?.value
          ? {
              id: datos.departamento.value,
              code: datos.departamento.code,
              slug: datos.departamento.slug,
              value: datos.departamento.label,
            }
          : null,
        municipio: datos.municipios?.value
          ? {
              id: datos.municipios.value,
              code: datos.municipios.code,
              slug: datos.municipios.slug,
              value: datos.municipios.label,
              departamentoId: datos.municipios.departamentoId,
            }
          : null,
        direccion: datos.direccion,
        correo: datos.correo,
        telefono: datos.telefono,
        actividad_economica: watch('actividades_economicas').map((item) => ({
          id: item.value,
          code: item.code,
          default: item.default ?? false,
          slug: item.slug,
          value: item.label,
        })),

        nit: datos.nit,
        nrc: datos.nrc,
        contribuyente,
        tipo_empresa: Number(datos.tipo_empresa.value),
      };
      payload = omitEmpty(payload);

      if (id) {
        const { data } = await patch(`mis-clientes/${id}`, payload);
        if (data) {
          loadingReload(false);
          Alert({
            title: 'Actualización de cliente',
            icon: 'success',
            text: 'Cliente actualizado correctamente',
          });
          navigate('/mis-clientes');
        }
      } else {
        const { data } = await post('/mis-clientes', payload);
        if (data) {
          loadingReload(false);
          Alert({
            title: 'Creación de cliente',
            icon: 'success',
            text: 'Cliente se creardo correctamente',
          });
          navigate('/mis-clientes');
        }
      }
    } catch (error) {
      loadingReload(false);
      const errorCode = error?.response?.data?.code;
      const errorKeys = error?.response?.data?.keys;
      let errorMessage = '';
      if (ERROR_TYPE_MESSAGE.includes(errorCode)) {
        errorMessage = `Error al guardar el campo ${errorKeys[0].toUpperCase()}`;
      }
      Alert({
        title: errorCode ? 'Verifique los datos' : 'Error al guardar',
        text: errorMessage,
        icon: errorCode ? 'warning' : 'error',
      });
    }
  };

  const onSubmit = async (datos) => {
    sendCliente(datos);
  };

  const agregarActividadEconomica = () => {
    if (watch('actividades_economicas').length === 2 && !porDefecto) {
      enqueueSnackbar('Debe seleccionar una actividad económica primaria', {
        variant: 'error',
        preventDuplicate: true,
      });
      return;
    }
    if (watch('actividades_economicas').length > 2) {
      enqueueSnackbar('No puede haber mas de 3 actividades economicas', {
        variant: 'error',
        preventDuplicate: true,
      });
      return;
    }

    const repetidos = watch('actividades_economicas').find(
      (item) => item.code === actividadEconmica.code,
    );

    if (repetidos) {
      enqueueSnackbar(
        `Actividad económica ${actividadEconmica.label} ya fue agregada`,
        {
          variant: 'error',
          preventDuplicate: true,
        },
      );
      return;
    }

    if (actividadEconmica) {
      setValue('actividades_economicas', [
        ...watch('actividades_economicas'),
        {
          value: actividadEconmica.value,
          label: actividadEconmica.label,
          code: actividadEconmica.code,
          slug: actividadEconmica.slug,
          default: porDefecto,
        },
      ]);

      setValue('actividad_economica', null);

      setPorDefecto(false);
    } else {
      Alert({
        title: 'Error',
        text: 'Por favor, seleccione una actividad económica',
        icon: 'error',
      });
    }
  };

  const eliminarActividadEconomica = (value) => {
    setValue('actividades_economicas', [
      ...watch('actividades_economicas').filter(
        (actividad) => actividad.value !== value,
      ),
    ]);

    setPorDefecto(false);
  };

  const inputNumeroDocumento = () => {
    const duiInput = (
      <InputMask
        key={maskNumeroReceptor}
        mask="________-_"
        showMask
        replacement={{ _: /\d/ }}
        type="text"
        name="numero_documento"
        id="numero_documento"
        placeholder="00000000000000"
        className={`form-control ${
          errors.numero_documento ? 'is-invalid' : ''
        }`}
        {...register('numero_documento')}
      ></InputMask>
    );
    const nitInput = (
      <InputMask
        key={maskNumeroReceptor}
        mask="____-______-___-_"
        showMask
        replacement={{ _: /\d/ }}
        type="text"
        name="numero_documento"
        id="numero_documento"
        placeholder="00000000000000"
        className={`form-control ${
          errors.numero_documento ? 'is-invalid' : ''
        }`}
        {...register('numero_documento')}
      ></InputMask>
    );
    switch (maskNumeroReceptor) {
      case 1:
        return duiInput;
      case 2:
        return nitInput;
      default:
        return (
          <input
            key={maskNumeroReceptor}
            type="text"
            name="numero_documento"
            id="numero_documento"
            placeholder="00000000000000"
            className={`form-control ${
              errors.numero_documento ? 'is-invalid' : ''
            }`}
            {...register('numero_documento')}
          ></input>
        );
    }
  };

  const nitNumeroDocumento = () => {
    const duiInput = (
      <InputMask
        key={maskNumeroNit}
        mask="________-_"
        showMask
        replacement={{ _: /\d/ }}
        type="text"
        name="nit"
        id="nit"
        placeholder="Ingrese el número de documento"
        className={`form-control ${errors.nit ? 'is-invalid' : ''}`}
        {...register('nit')}
      ></InputMask>
    );
    const nitInput = (
      <InputMask
        key={maskNumeroNit}
        mask="____-______-___-_"
        showMask
        replacement={{ _: /\d/ }}
        type="text"
        name="nit"
        id="nit"
        placeholder="Ingrese el número de documento"
        className={`form-control ${errors.nit ? 'is-invalid' : ''}`}
        {...register('nit')}
      ></InputMask>
    );
    switch (maskNumeroNit) {
      case 1:
        return duiInput;
      case 2:
        return nitInput;
      default:
        return (
          <input
            key={maskNumeroNit}
            type="text"
            name="numero_documento"
            id="numero_documento"
            placeholder="Ingrese el número de documento"
            className={`form-control ${errors.nit ? 'is-invalid' : ''}`}
            {...register('nit')}
          ></input>
        );
    }
  };
  const updateInputMaskConfig = (value) => {
    if (maskNumeroReceptor === value) {
      return;
    }
    setMaskNumeroReceptor(value);
    setValue('numero_documento', '');
  };
  const configureInputMaskForNumeroDocumento = (documentType) => {
    if (documentType === '13') {
      updateInputMaskConfig(1);
    } else if (documentType === '36.2') {
      //Dui homologado a NIT
      updateInputMaskConfig(1);
    } else if (documentType === '36') {
      updateInputMaskConfig(2);
    } else {
      updateInputMaskConfig(0);
    }
  };
  const updateInputNitMaskConfig = (value) => {
    if (maskNumeroNit === value) {
      return;
    }
    setMaskNumeroNit(value);
    setValue('nit', '');
  };
  const configureInputMaskForNumeroNit = (documentType) => {
    if (documentType === '13') {
      updateInputNitMaskConfig(1);
    } else if (documentType === '36.2') {
      //Dui homologado a NIT
      updateInputNitMaskConfig(1);
    } else if (documentType === '36') {
      updateInputNitMaskConfig(2);
    } else {
      updateInputNitMaskConfig(0);
    }
  };

  return (
    <>
      <CardWrapperTools
        title={
          id
            ? 'Panel de actualización de clientes'
            : 'Panel de registro de clientes'
        }
        footer={
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex justify-content-center">
                <button
                  className="btn btn-secondary btn-lg"
                  style={{ marginRight: '20px' }}
                  onClick={() => navigate('/mis-clientes')}
                >
                  <span className="fas fa-arrow-left"></span> Salir
                </button>
                <button
                  className="btn btn-primary btn-lg"
                  onClick={handleSubmit(onSubmit)}
                >
                  <span
                    style={{ marginRight: '5px' }}
                    className="fas fa-paper-plane"
                  ></span>
                  {id ? 'Actualizar' : 'Guardar'}
                </button>
              </div>
            </div>
          </div>
        }
      >
        <div className="row g-3">
          <CardWrapperTools title="Datos del cliente">
            <div className="row  g-3">
              <div className="col-md-3 text-center">
                <label className="form-label">¿Es contribuyente?</label>

                <div className="d-flex justify-content-center mb-2">
                  <div className="form-check form-switch">
                    <input
                      {...register('contribuyente')}
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckDefault"
                      onChange={(e) => {
                        setContribuyente(e.target.checked);
                        if (e.target.checked) {
                          setMaskNumeroNit(2);
                        }
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexSwitchCheckDefault"
                    >
                      Si
                    </label>
                    {errors.contribuyente && (
                      <div className="invalid-feedback">
                        {errors.contribuyente.message}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <label>Nombre o Razón Social:</label>
                <input
                  type="text"
                  name="nombre_cliente"
                  id="nombre_cliente"
                  placeholder="John Smith"
                  className={`form-control ${
                    errors.nombre_cliente ? 'is-invalid' : ''
                  }`}
                  {...register('nombre_cliente')}
                />
                {errors.nombre_cliente && (
                  <div className="invalid-feedback">
                    {errors.nombre_cliente.message}
                  </div>
                )}
              </div>

              <div className="col-md-3">
                <label>Nombre comercial:</label>
                <input
                  type="text"
                  name="nombre_comercial"
                  id="nombre_comercial"
                  placeholder="Escriba el nombre comercial"
                  className={`form-control ${
                    errors.nombre_comercial ? 'is-invalid' : ''
                  }`}
                  {...register('nombre_comercial')}
                />
                {errors.nombre_comercial && (
                  <div className="invalid-feedback">
                    {errors.nombre_comercial.message}
                  </div>
                )}
              </div>

              {!contribuyente && (
                <>
                  <div className="col-md-3">
                    <label className="form-label" htmlFor="alias">
                      Tipo de documento:
                    </label>

                    <Controller
                      key={100}
                      name="tipo_documento"
                      id="tipo_documento"
                      control={control}
                      render={({ field }) => (
                        <AsyncSelect
                          {...field}
                          cacheOptions
                          loadOptions={laodTipoDocumento}
                          defaultOptions
                          placeholder="Tipo de documento"
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderColor: errors.tipo_documento
                                ? 'red'
                                : provided.borderColor,
                            }),
                          }}
                          onChange={(event) => {
                            const documentType = event?.code; // es code el codigo
                            configureInputMaskForNumeroDocumento(documentType);
                            field.onChange(event);
                          }}
                        />
                      )}
                    />
                    {errors.tipo_documento && (
                      <div className="invalid-feedback">
                        {'Tipo de documento es requerido'}
                      </div>
                    )}
                  </div>

                  <div className="col-md-3">
                    <label htmlFor="numDocumento">Número de documento:</label>
                    {inputNumeroDocumento()}
                    {errors.numero_documento && (
                      <div className="invalid-feedback">
                        {errors.numero_documento.message}
                      </div>
                    )}
                  </div>
                </>
              )}

              {contribuyente && (
                <>
                  <div className="col-md-3">
                    <label className="form-label" htmlFor="alias">
                      Tipo de NIT:
                    </label>
                    <Controller
                      key={101}
                      name="tipo_documento_nit"
                      id="tipo_documento_nit"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          cacheOptions
                          options={[
                            {
                              value: '36',
                              label: 'NIT',
                              code: '36',
                              slug: 'nit',
                            },
                            {
                              value: '36.2',
                              label: 'NIT (DUI Homologado)',
                              code: '36.2',
                              slug: 'dui',
                            },
                          ]}
                          defaultValue={{
                            value: '36',
                            label: 'NIT',
                            code: '36',
                            slug: 'nit',
                          }}
                          placeholder="Tipo NIT"
                          onChange={(event) => {
                            const documentType = event?.code; // es code el codigo
                            configureInputMaskForNumeroNit(documentType);
                            field.onChange(event);
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="numDocumento">Número de NIT:</label>
                    {nitNumeroDocumento()}
                    {errors.nit && (
                      <div className="invalid-feedback">
                        {errors.nit.message}
                      </div>
                    )}
                  </div>
                </>
              )}

              {contribuyente && (
                <div className="col-md-3">
                  <label htmlFor="numDocumento">NRC:</label>
                  <input
                    type="text"
                    name="nrc"
                    id="nrc"
                    placeholder="0000000"
                    className={`form-control ${errors.nrc ? 'is-invalid' : ''}`}
                    {...register('nrc')}
                  />
                  {errors.nrc && (
                    <div className="invalid-feedback">{errors.nrc.message}</div>
                  )}
                </div>
              )}

              <div className="col-md-3">
                <label htmlFor="departamento">Departamento:</label>
                <Controller
                  name="departamento"
                  control={control}
                  render={({ field }) => (
                    <AsyncSelect
                      {...field}
                      cacheOptions
                      loadOptions={loadDepartamento}
                      defaultOptions
                      placeholder="Departamento"
                      onChange={(selectedOption) => {
                        field.onChange(selectedOption);
                        fetchMunicipios(
                          selectedOption ? selectedOption.value : null,
                        );
                        setValue('municipios', null);
                      }}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          borderColor: errors.departamento
                            ? 'red'
                            : provided.borderColor,
                        }),
                      }}
                    />
                  )}
                />
                {errors.departamento && (
                  <div className="invalid-feedback">
                    {'Departamento es requerido'}
                  </div>
                )}
              </div>

              <div className="col-md-3">
                <label htmlFor="municipios">Municipio:</label>
                <Controller
                  name="municipios"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      cacheOptions
                      options={municipioOptions}
                      defaultOptions
                      placeholder="Seleccione una opción"
                      isDisabled={!municipioOptions.length}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          borderColor: errors.municipio
                            ? 'red'
                            : provided.borderColor,
                        }),
                      }}
                    />
                  )}
                />
                {errors.municipios && (
                  <div className="invalid-feedback">
                    {'Municipio es requerido'}
                  </div>
                )}
              </div>

              <div className="col-md-3">
                <label>Correo:</label>
                <input
                  type="email"
                  name="correo"
                  id="correo"
                  placeholder="pruebas@gmail.com"
                  className={`form-control ${
                    errors.correo ? 'is-invalid' : ''
                  }`}
                  {...register('correo')}
                />
                {errors.correo && (
                  <div className="invalid-feedback">
                    {errors.correo.message}
                  </div>
                )}
              </div>

              {contribuyente && (
                <div className="col-md-3">
                  <label htmlFor="empresa">Tipo de empresa:</label>
                  <Controller
                    name="tipo_empresa"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        cacheOptions
                        options={empresa}
                        placeholder="Seleccione una opción"
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            borderColor: errors.tipo_empresa
                              ? 'red'
                              : provided.borderColor,
                          }),
                        }}
                      />
                    )}
                  />
                  {errors.tipo_empresa && (
                    <div className="invalid-feedback">
                      {errors.tipo_empresa.message}
                    </div>
                  )}
                </div>
              )}
              <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                <label>Teléfono:</label>
                <Controller
                  name="telefono"
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <PhoneInput
                      placeholder="0000-00"
                      country={'sv'}
                      enableSearch={true}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      inputRef={ref}
                      inputStyle={{ width: '100%' }}
                    />
                  )}
                />
                {errors.telefono && (
                  <div className="invalid-feedback">
                    {errors.telefono.message}
                  </div>
                )}
              </div>

              <div className="col-lg-12">
                <label>Dirección:</label>
                <textarea
                  rows={5}
                  {...register('direccion')}
                  className="form-control"
                  name="direccion"
                  id="direccion"
                  placeholder="Escriba la dirección"
                ></textarea>

                {errors.direccion && (
                  <div className="invalid-feedback">
                    {errors.direccion.message}
                  </div>
                )}
              </div>
              <div className="col-lg-12"></div>
            </div>
          </CardWrapperTools>

          {contribuyente && (
            <CardWrapperTools
              title="Selección de actividades económicas"
              footer={
                <div className="text-center">
                  <button
                    className="btn btn-lg btn-primary"
                    onClick={agregarActividadEconomica}
                  >
                    <span className="fas fa-plus me-1"></span>
                    Agregar
                  </button>
                </div>
              }
            >
              <div className="row g-3">
                <div className="col-lg-4">
                  <label className="form-label">Actividad Económica:</label>
                  <Controller
                    name="actividad_economica"
                    control={control}
                    render={({ field }) => (
                      <AsyncSelect
                        {...field}
                        cacheOptions
                        loadOptions={loadActividadEconomica}
                        defaultOptions
                        isClearable={true}
                        placeholder="Seleccione una opción"
                        onChange={(e) => {
                          field.onChange(e);
                          setActividadEconmica(e);
                        }}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            borderColor: errors.actividad_economica
                              ? 'red'
                              : provided.borderColor,
                          }),
                        }}
                      />
                    )}
                  />
                  {errors.actividades_economicas && (
                    <div className="invalid-feedback">
                      {errors.actividades_economicas.message}
                    </div>
                  )}
                </div>

                {watch('actividades_economicas')?.filter(
                  (actividad) => actividad?.default === true,
                ).length === 0 && (
                  <div className="col-lg-2 text-center">
                    <label className="form-label"> ¿Actividad Primaria?</label>

                    <div className="d-flex justify-content-center mb-2">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          name="porDefecto"
                          type="checkbox"
                          id="porDefecto"
                          onChange={(e) => {
                            setPorDefecto(e.target.checked);
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="actividadDefecto"
                        >
                          Si
                        </label>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </CardWrapperTools>
          )}

          {watch('actividades_economicas')?.length > 0 && (
            <CardWrapperTools title="Actividades económicas seleccionadas">
              <div className="row">
                <div className="col-lg-12">
                  <div className="col-md-12 mt-5">
                    <div className="table-responsive scrollbar">
                      <table className="table table-hover table-striped overflow-hidden">
                        <thead data-bs-theme="light">
                          <tr className="bg-primary text-white dark__bg-100">
                            <th className="border-0 text-center">Código</th>
                            <th className="border-0 text-center">Nombre</th>
                            <th className="border-0 text-center">
                              Actividad Primaria
                            </th>
                            <th className="border-0 text-center">Acciones</th>
                          </tr>
                        </thead>

                        <tbody>
                          {watch('actividades_economicas')?.map(
                            (item, index) => (
                              <tr key={index}>
                                <td className="text-center">{item.code}</td>
                                <td className="text-center">{item.label}</td>
                                <td className="text-center">
                                  {item.default ? 'Si' : 'No'}
                                </td>
                                <td className="text-center">
                                  <button
                                    className="btn btn-danger"
                                    onClick={() =>
                                      eliminarActividadEconomica(item.value)
                                    }
                                  >
                                    <span className="fas fa-trash-alt me-1"></span>
                                    Eliminar
                                  </button>
                                </td>
                              </tr>
                            ),
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </CardWrapperTools>
          )}
        </div>
      </CardWrapperTools>
    </>
  );
}
