/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { get } from '../../../services/http/http.service';
import { getSession } from '../../../services/encrytion/encryption.service';
import httpAuth from '../../../services/http/http.auth.services';
import { AMBIENTE_DTE, TIPO_DOCUMENTOS } from '../../../config/constants';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

export default function FacturaData() {
  const [dataFactura, setDataFactura] = useState({
    label: 'NO DATA',
    count: 0,
    total: '0.00',
  });

  const [sucursalSet, setSucursal] = useState([]);
  const [sucursalSeleccionada, setSucursalSeleccionada] = useState(null);
  const [selectedSucursal, setSelectedSucursal] = useState('');
  const [sucursalCount, setSucursalCount] = useState(0);
  const [currenteDate, setCurrentDate] = useState('');

  const { id } = getSession('clienteSeleccionado');

  useEffect(() => {
    if (!id) {
      return;
    }

    getSucursalesByCliente(id);
  }, [id]);

  const getDataByDia = async (
    clienteId,
    date,
    ambiente,
    sucursalId,
    tipoDoc,
  ) => {
    setCurrentDate(dayjs(date).format('YYYY-MM-DD'));
    const { data } = await get(`/estadisticas/dia/${clienteId}`, {
      date: dayjs(date).format('YYYY-MM-DDTHH:mm:ssZ'),
      ambiente,
      sucursal: sucursalId,
      tipoDocumento: tipoDoc,
    });
    setDataFactura(data);
  };

  const getSucursalesByCliente = async (clienteId) => {
    try {
      const sucursalSeleccionadaG = await getSession('sucursal');
      setSucursalSeleccionada(sucursalSeleccionadaG);
      const { data } = await httpAuth.get(`/sucursales/${clienteId}`);
      const mappedSucursales = data.results?.map((sucursal) => ({
        value: sucursal?._id,
        label: `${sucursal.codigo_punto_venta_mh} - ${sucursal.tipo_establecimiento.value} - ${sucursal.direccion}`,
        isDefault: sucursal.default,
        activo: sucursal.activo,
      }));

      setSucursal(mappedSucursales);

      const sucursalesActivas = mappedSucursales.filter(
        (sucursal) => sucursal.activo === true,
      );

      const sucursalDefault = mappedSucursales.find(
        (sucursal) => sucursal.isDefault && sucursal.activo === true,
      );

      const dateFinal = dayjs().tz('America/El_Salvador');
      getDataByDia(
        clienteId,
        dateFinal,
        process.env.REACT_APP_NODE_ENV === 'production'
          ? AMBIENTE_DTE.PRODUCCION
          : AMBIENTE_DTE.PRUEBAS,
        sucursalSeleccionadaG
          ? sucursalSeleccionadaG?._id
          : sucursalDefault.value,
      );
      setSelectedSucursal(
        sucursalSeleccionadaG
          ? sucursalSeleccionadaG?._id
          : sucursalDefault.value,
      );

      setSucursalCount(sucursalesActivas.length);
    } catch (error) {
      console.error('Error obteniendo sucursales:', error);
    }
  };

  const handleSucursalChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedSucursal(selectedValue);

    const dateFinal = currenteDate;
    getDataByDia(
      id,
      dateFinal,
      process.env.REACT_APP_NODE_ENV === 'production'
        ? AMBIENTE_DTE.PRODUCCION
        : AMBIENTE_DTE.PRUEBAS,
      selectedValue,
    );
  };

  const handleChangesTipoDocumento = (e) => {
    const selectedTipoDocumento = e.target.value;
    getDataByDia(
      id,
      currenteDate,
      process.env.REACT_APP_NODE_ENV === 'production'
        ? AMBIENTE_DTE.PRODUCCION
        : AMBIENTE_DTE.PRUEBAS,
      selectedSucursal,
      selectedTipoDocumento !== '' ? selectedTipoDocumento : null,
    );
  };

  return (
    <>
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h5 className="card-title">Ventas Diarias</h5>
            <div className="d-flex flex-column align-items-center">
              <div className="row g-1">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                  <label htmlFor="">Fecha:</label>
                  <input
                    className="form-control mb-3"
                    type="date"
                    value={currenteDate}
                    onChange={(e) => {
                      getDataByDia(
                        id,
                        e.target.value,
                        process.env.REACT_APP_NODE_ENV === 'production'
                          ? AMBIENTE_DTE.PRODUCCION
                          : AMBIENTE_DTE.PRUEBAS,
                        selectedSucursal,
                      );
                    }}
                  />
                </div>
                {sucursalCount >= 2 && (
                  <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <label htmlFor="">Sucursal:</label>
                    <select
                      className="form-control mb-3"
                      value={selectedSucursal}
                      onChange={handleSucursalChange}
                      disabled={sucursalSeleccionada !== null}
                    >
                      <option value="">Seleccione una sucursal</option>
                      {sucursalSet?.map((sucursalInfo, index) => {
                        return (
                          <option key={index} value={sucursalInfo.value}>
                            {sucursalInfo.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                )}
                <div
                  className={
                    sucursalCount >= 2
                      ? 'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12'
                      : 'col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12'
                  }
                >
                  <label htmlFor="">Tipo de Documento:</label>
                  <select
                    className="form-control mb-3"
                    name="tipoDocument"
                    id="tipoDocument"
                    onChange={handleChangesTipoDocumento}
                  >
                    <option value={''}>Todos</option>
                    <option value={TIPO_DOCUMENTOS.FACTURA}>Factura</option>
                    <option value={TIPO_DOCUMENTOS.CREDITO_FISCAL}>
                      Crédito Fiscal
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body text-center">
            <div className="display-4 text-primary mb-1">
              <i className="fas fa-calendar-day fs-6"></i>
            </div>
            <h2>${dataFactura.total}</h2>
            <p className="card-text">{dataFactura.count} Documentos Emitidos</p>
          </div>
        </div>
      </div>
    </>
  );
}
