import React, { useEffect } from 'react';
import {
  destroyItemSession,
  getSession,
} from '../../../../services/encrytion/encryption.service';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import CardWrapperTools from '../../../../Components/Common/Cards/CardWrapperTools';
import CollapseCard from '../../../../Components/Common/Cards/CollapseCard';
import ReceptorForm from './components/ReceptorForm';
import EmisorForm from './components/EmisorForm';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  capitalizeWords,
  defaultFacturaValues,
  getTipoGeneracionDte,
  processNumeroDocumentoReceptor,
  validationSchema,
} from './utils/FacturaV2';
import { split } from 'lodash';
import { convertirNumeroALetras } from '../../../../utils/NumeroLetras';
import {
  Alert,
  AlertQuestionYesNo,
  loadingReload,
} from '../../../../services/alerts/alerts';
import { enqueueSnackbar } from 'notistack';
import { post } from '../../../../services/http/http.service';
import DatosFacturaV2Form from './components/DatosFacturaV2Form';
import {
  calcularIvaEnTotal,
  redondearA2Decimales,
} from '../../../../utils/Utils';
const Recibo = () => {
  const ambiente = getSession('ambiente');
  const cliente = getSession('clienteSeleccionado');
  const navigate = useNavigate();
  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      reteRenta: '0.00',
      ivaPerci1: '0.00',
      ivaRete1: '0.00',
      departamentoReceptor: null,
      condicionOperacion: '1',
      pagos: [],
      documentoRelacionado: [],
      documentosAsociados: [],
      descuentoGravada: '0.00',
      descuentoExenta: '0.00',
      descuentoNoSujeta: '0.00',
    },
  };

  const methods = useForm(formOptions);

  const onSubmit = () => {
    const datosDocumentoRelacionado = methods.getValues('documentoRelacionado');
    const documentosAsociados = methods.getValues('documentosAsociados');
    const cuerpoDocumento = methods.getValues('cuerpoDocumento');
    const resumen = methods.getValues('resumen');

    //Calculamos el total de los pagos
    const pagos = methods.getValues('pagos').reduce((acc, item) => {
      return acc + Number(item.montoPago);
    }, 0);

    if (
      pagos >
      Number(
        parseFloat(
          resumen.totalPagar -
            methods.watch('descuentoGravada') -
            methods.watch('descuentoExenta') -
            methods.watch('descuentoNoSujeta') -
            resumen?.tributos
              .filter((t) => t.mode === 'porcentaje')
              .reduce(
                (acc, item) =>
                  acc + methods.watch('descuentoGravada') * item.amount[0],
                0,
              ),
        ).toFixed(2),
      )
    ) {
      Alert({
        title: 'Error en los pagos',
        text: 'El monto total de los pagos no puede ser mayor al monto total de la operación',
        icon: 'error',
      });

      return;
    }
    const descuentoVentaGravada = Number(methods.watch('descuentoGravada'));
    const descuentoVentaExenta = Number(methods.watch('descuentoExenta'));
    const descuentoVentaNoSujeta = Number(methods.watch('descuentoNoSujeta'));
    //Calculamos el total de los descuentos
    const totalDescu = Number(
      parseFloat(
        //Descuentos globales
        descuentoVentaGravada +
          descuentoVentaExenta +
          descuentoVentaNoSujeta +
          resumen.totalDescu, // deberia contener la suma de los descuentos de los items
      ),
    );
    //Calculamos el sub total de las ventas
    const subTotalVentas = Number(parseFloat(resumen.subTotalVentas));
    const subtotal = Number(
      parseFloat(
        subTotalVentas -
          descuentoVentaGravada -
          descuentoVentaExenta -
          descuentoVentaNoSujeta,
      ).toFixed(2),
    );
    const montoTotalOperacion = Number(
      parseFloat(
        resumen.montoTotalOperacion -
          methods.watch('descuentoGravada') -
          methods.watch('descuentoExenta') -
          methods.watch('descuentoNoSujeta') -
          resumen?.tributos
            .filter((t) => t.mode === 'porcentaje')
            .reduce(
              (acc, item) =>
                acc + methods.watch('descuentoGravada') * item.amount[0],
              0,
            ),
      ).toFixed(2),
    );
    // Si hay descuentos en ventas gravadas, calculamos el iva en el total nuevo
    const totalIva =
      descuentoVentaGravada > 0
        ? calcularIvaEnTotal(subtotal)
        : redondearA2Decimales(resumen.totalIva);

    //Calculamos el total a pagar
    const totalAPagar = Number(
      parseFloat(
        montoTotalOperacion +
          resumen.totalNoGravado -
          resumen.reteRenta -
          resumen.ivaRete1,
      ).toFixed(2),
    );

    const nombreClienteReceptor =
      methods.getValues('nombreClienteReceptor') === ''
        ? defaultFacturaValues.nombreClienteReceptor
        : methods.getValues('nombreClienteReceptor');
    const tipoDocumentoReceptor = methods.getValues(
      'tipoDocumentoReceptor',
    )?.value;
    const numDocumentoReceptor =
      methods.getValues('numeroDocumentoReceptor') === ''
        ? null
        : methods.getValues('numeroDocumentoReceptor');
    const correoElectronicoReceptor =
      methods.getValues('correoElectronicoReceptor') === ''
        ? null
        : methods.getValues('correoElectronicoReceptor');
    const telefonoReceptor =
      methods.getValues('telefonoReceptor') === ''
        ? null
        : methods.getValues('telefonoReceptor');
    const direccionReceptor =
      methods.getValues('departamentoReceptor') === null
        ? null
        : {
            municipio: methods.getValues('municipioReceptor')?.value,
            complemento:
              methods.getValues('complementoReceptor') === ''
                ? capitalizeWords(
                    methods.getValues('departamentoReceptor')?.label,
                  ) +
                  ', ' +
                  capitalizeWords(methods.getValues('municipioReceptor')?.label)
                : methods.getValues('complementoReceptor'),
            departamento: methods.getValues('departamentoReceptor')?.value,
          };

    //Procesamos el numeroDocumentoReceptor
    const documentoReceptorProcesado = processNumeroDocumentoReceptor(
      tipoDocumentoReceptor,
      numDocumentoReceptor,
    );
    const FacturaData = {
      documentoRelacionado:
        datosDocumentoRelacionado.length > 0
          ? datosDocumentoRelacionado.map((item) => ({
              tipoDocumento: item?.tipoDocumentoRel,
              tipoGeneracion: getTipoGeneracionDte(item?.tipoGeneracion),
              numeroDocumento: item?.numeroDocumentoRel,
              fechaEmision: item?.fechaGeneracionRel,
            }))
          : null,
      emisor: {
        codActividad: methods.getValues('actividadEconomicaEmisor')?.value,
        descActividad: split(
          methods.getValues('actividadEconomicaEmisor')?.label,
          '-',
          2,
        )[1].trim(),
      },
      receptor: {
        tipoDocumento: documentoReceptorProcesado.tipo,
        numDocumento: documentoReceptorProcesado.numero,
        correo: correoElectronicoReceptor,
        nombre: nombreClienteReceptor,
        telefono: telefonoReceptor,
        direccion: direccionReceptor,
        nrc: null,
      },
      otrosDocumentos:
        documentosAsociados.length > 0
          ? documentosAsociados.map((da) => ({
              medico:
                da.medico !== null
                  ? {
                      nombre: da.medico.nombre,
                      nit: da.medico.nit,
                      docIdentificacion: da.medico.docIdentificacion,
                      tipoServicio: Number(da.medico.tipoServicio),
                    }
                  : null,
              detalleDocumento: da.detalleDocumento,
              descDocumento: da.descDocumento,
              codDocAsociado: da.codDocAsociado,
            }))
          : null,
      ventaTercero:
        methods.getValues('nitVentaTerceros') &&
        methods.getValues('nombreVentaTerceros')
          ? {
              nit: methods.getValues('nitVentaTerceros') || null,
              nombre: methods.getValues('nombreVentaTerceros') || null,
            }
          : null,
      cuerpoDocumento: cuerpoDocumento.map((item, index) => ({
        numItem: index + 1,
        tipoItem: item.tipoItem,
        numeroDocumento: item.numeroDocumento || null,
        cantidad: item.cantidad,
        codigo: item.codigo?.toString(),
        codTributo: item.codTributo || null,
        uniMedida: item.uniMedida,
        descripcion: item.descripcion,
        precioUni: Number(parseFloat(item.precioUni).toFixed(2)),
        montoDescu: Number(parseFloat(item.montoDescu).toFixed(2)),
        ventaNoSuj: Number(parseFloat(item.ventaNoSuj).toFixed(2)),
        ventaExenta: Number(parseFloat(item.ventaExenta).toFixed(2)),
        ventaGravada: Number(parseFloat(item.ventaGravada).toFixed(2)),
        tributos:
          item.tributos && item.tributos.length > 0 ? item.tributos : null,
        psv: 0,
        noGravado: Number(parseFloat(item.noGravado).toFixed(2)),
        ivaItem: Number(parseFloat(item.ivaItem).toFixed(2)),
      })),
      resumen: {
        pagos: methods.getValues('pagos')
          ? methods.getValues('pagos').map((item) => ({
              codigo: item.codigo,
              montoPago: Number(parseFloat(item.montoPago).toFixed(2)),
              referencia: item.referencia,
              plazo: item.plazo?.value,
              periodo: Number(item.periodo),
            }))
          : [],
        tributos: resumen.tributos
          .map((item) => {
            if (item.mode === 'libre') {
              return {
                codigo: item.codigo,
                descripcion: item.descripcion,
                valor: methods.getValues(item.codigo)
                  ? Number(
                      parseFloat(methods.getValues(item.codigo)).toFixed(2),
                    )
                  : 0,
              };
            }

            if (item.mode === 'porcentaje') {
              return {
                codigo: item.codigo,
                descripcion: item.descripcion,
                valor: Number(
                  parseFloat(
                    item.valor -
                      methods.getValues('descuentoGravada') * item.amount[0],
                  ).toFixed(2),
                ),
              };
            }
            return {
              codigo: item.codigo,
              descripcion: item.descripcion,
              valor: Number(parseFloat(item.valor).toFixed(2)),
            };
          })
          .filter((item) => item.valor > 0),

        subTotal: Number(parseFloat(subtotal).toFixed(2)),
        ivaRete1: Number(parseFloat(resumen.ivaRete1).toFixed(2)),
        reteRenta: Number(parseFloat(resumen.reteRenta).toFixed(2)),
        descuNoSuj: Number(
          parseFloat(methods.watch('descuentoNoSujeta')).toFixed(2),
        ),
        totalDescu: Number(parseFloat(totalDescu).toFixed(2)),
        totalNoSuj: Number(parseFloat(resumen.totalNoSuj).toFixed(2)),
        descuExenta: Number(
          parseFloat(methods.watch('descuentoExenta')).toFixed(2),
        ),
        totalExenta: Number(parseFloat(resumen?.totalExenta || 0).toFixed(2)),
        totalLetras: convertirNumeroALetras(
          Number(parseFloat(totalAPagar).toFixed(2)),
        )?.trim(),

        saldoFavor: 0,

        totalPagar: Number(parseFloat(totalAPagar).toFixed(2)),

        totalNoGravado: Number(parseFloat(resumen.totalNoGravado).toFixed(2)),
        descuGravada: Number(methods.watch('descuentoGravada')),
        totalGravada: Number(parseFloat(resumen.totalGravada).toFixed(2)),
        subTotalVentas: Number(parseFloat(subTotalVentas).toFixed(2)),
        condicionOperacion: Number(methods.getValues('condicionOperacion')),
        montoTotalOperacion: Number(parseFloat(montoTotalOperacion).toFixed(2)),
        numPagoElectronico: null,
        porcentajeDescuento: 0,
        totalIva: Number(parseFloat(totalIva).toFixed(2)),
      },
      extension: {
        nombEntrega: methods.getValues('nombreResponsableEmisor') || null,
        docuEntrega:
          methods.getValues('numeroDocumentoResponsableEmisor') || null,
        nombRecibe: methods.getValues('nombreResponsableReceptor') || null,
        docuRecibe:
          methods.getValues('numeroDocumentoResponsableReceptor') || null,
        observaciones: methods.getValues('observaciones') || null,
        placaVehiculo: null,
      },
    };

    AlertQuestionYesNo({
      title: 'Generar documento',
      text: '¿Está seguro que desea generar el recibo?',
      icon: 'info',
    }).then(async (result) => {
      try {
        if (result) {
          const headers = {
            'x-ambiente': ambiente,
            'x-branchoffice-id': methods.getValues('establecimientoEmisor')
              ?.value,
          };

          loadingReload(
            true,
            'Generando documento',
            'Espere mientras se genera el documento, esto puede tardar unos segundos',
          );
          const { data, status } = await post(
            'dte/recibo',
            FacturaData,
            headers,
          );

          if (status === 201) {
            if (data?.selloRecibido) {
              loadingReload(false);
              enqueueSnackbar('Recibo generado con éxito', {
                variant: 'success',
                preventDuplicate: true,
              });
              navigate(
                `/dte/consulta/${data?.codigoGeneracion}/detalle/${cliente?.id}`,
              );

              return;
            }
            loadingReload(false);
            Alert({
              title:
                'El documento fue generado con éxito, pero tiene observaciones',
              text: 'Por favor contacte con el administrador del sistema para más información',
              icon: 'warning',
            });

            navigate(
              `/dte/consulta/${data?.codigoGeneracion}/detalle/${cliente?.id}`,
            );

            return;
          } else {
            loadingReload(false);
            Alert({
              title: 'Error al generar el documento',
              text: 'Por favor contacte con el administrador del sistema para más información',
              icon: 'error',
            });
          }
        }
      } catch (error) {
        loadingReload(false);
        Alert({
          title: 'Error al generar el documento',
          text: 'Por favor contacte con el administrador del sistema para más información',
          icon: 'error',
        });
      }
    });
  };

  // function calcularIvaDeFactura(ivaActual, subTotalVentas, descuentosGlobales) {
  //   if (descuentosGlobales <= 0) {
  //     return ivaActual;
  //   }
  //   return calcularIvaEnTotal(subTotalVentas - descuentosGlobales);
  // }

  useEffect(() => {
    if (!ambiente) {
      navigate('/dte');
    }

    return () => {
      destroyItemSession('ambiente');
    };
  }, [ambiente, navigate]);
  return (
    <FormProvider {...methods}>
      <CardWrapperTools
        title="Panel de emisión Recibos"
        footer={
          <>
            <div className="row">
              <div className="col-lg-12">
                <div className="d-flex justify-content-center">
                  <button
                    className="btn btn-primary btn-lg"
                    onClick={methods.handleSubmit(onSubmit)}
                  >
                    <span className="far fa-paper-plane"></span> Generar Recibo
                  </button>
                </div>
              </div>
            </div>
            {ambiente === '00' && (
              <div className="row mt-3">
                <div className="col-lg-12">
                  <div className="alert alert-warning text-center" role="alert">
                    <i className="fa fa-exclamation-triangle"></i>
                    <span className="p-1">
                      Este documento será generado como prueba
                    </span>
                  </div>
                </div>
              </div>
            )}
          </>
        }
        tools={
          ambiente === '00' ? (
            <span className="badge badge-subtle-warning fs--1">
              <i className="fa fa-exclamation-triangle"></i>
              <span className="p-1">Recibo de prueba</span>
            </span>
          ) : null
        }
      >
        <div className="row">
          <div className="col-lg-12">
            <CollapseCard title={'Datos de emisor y receptor'}>
              <div className="row mt-2">
                <div className="col-12">
                  <>
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          id="home-tab"
                          data-bs-toggle="tab"
                          href="#datosReceptor"
                          role="tab"
                          aria-controls="datosReceptor"
                          aria-selected="true"
                        >
                          Datos del Receptor
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="profile-tab"
                          data-bs-toggle="tab"
                          href="#datosEmisor"
                          role="tab"
                          aria-controls="datosEmisor"
                          aria-selected="false"
                        >
                          Datos del Emisor
                        </a>
                      </li>
                    </ul>

                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="datosReceptor"
                        role="tabpanel"
                        aria-labelledby="datosReceptor"
                      >
                        <ReceptorForm />
                      </div>
                      <div
                        className="tab-pane fade"
                        id="datosEmisor"
                        role="tabpanel"
                        aria-labelledby="datosEmisor"
                      >
                        <EmisorForm />
                      </div>
                    </div>
                  </>
                </div>
              </div>
            </CollapseCard>
          </div>

          <div className="col-lg-12">
            <CollapseCard title={'Datos del Recibo'}>
              <div className="row">
                <div className="col-lg-12">
                  <DatosFacturaV2Form />
                </div>
              </div>
            </CollapseCard>
          </div>
        </div>
      </CardWrapperTools>
    </FormProvider>
  );
};

export default Recibo;
