import React, { Fragment } from 'react';
import ClienteUpdate from '../admin/clientes/ClienteUpdate';
import FacturaData from './estadisticas/FacturaData';
import FacturaDataMonth from './estadisticas/FacturaDataMonth';
import { getSession } from '../../services/encrytion/encryption.service';

const Home = () => {
  const modulos = getSession('modulos');
  return (
    <Fragment>
      <div className="row g-3">
        {modulos.includes('dfc82d21-a787-4a8a-9983-bc6da8e639d3') && (
          <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12">
            <div className="row">
              <div className="col-12">
                <FacturaData />
              </div>
            </div>
          </div>
        )}
        {modulos.includes('911b7a83-94cf-46b6-94eb-baa9adb9dfc4') && (
          <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12">
            <div className="row">
              <div className="col-12">
                <FacturaDataMonth />
              </div>
            </div>
          </div>
        )}
        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div className="row">
            <div className="col-12">
              <ClienteUpdate readOnly={true} />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Home;
