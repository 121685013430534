import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
} from 'react';
import {
  destroyItemSession,
  getSession,
} from '../../../../services/encrytion/encryption.service';
import { enqueueSnackbar } from 'notistack';
import { get } from '../../../../services/http/http.service';
import _debounce from 'lodash/debounce';
import { useSearchParams } from 'react-router-dom';
import ModalReporte from '../components/ModalReporte';
import { useNavigate } from 'react-router-dom';
import { getDteType, getTipoDocumento } from '../../../../utils/dte/dte.util';
import { getLocalDate } from '../../../../services/date/date';
import ValidateErrorDte from './components/ValidateErrorDte';
import './consulta.css';
import Action from './components/Action';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// Extender dayjs con los plugins
dayjs.extend(utc);
dayjs.extend(timezone);

const ConsultaV2 = () => {
  const navigate = useNavigate();
  const modalReporteRef = useRef(null);
  const pageDefault = 1;
  const limitDefault = 10;
  const ambienteDefault = '01';
  const [searchParams, setSearchParams] = useSearchParams();
  const [dteByClient, setDteByCliente] = useState([]);
  const [page, setPage] = useState(searchParams.get('page') || pageDefault);
  const [limit, setLimit] = useState(searchParams.get('limit') || limitDefault);
  const [ambiente, setAmbiente] = useState(ambienteDefault);
  const [search, setSearch] = useState('');
  const [cliente, setCliente] = useState(null);
  const [tiposDte, setTiposDte] = useState([]);
  const [tipoDte, setTipoDte] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const getDteByClienteId = useCallback(async () => {
    try {
      const cliente = getSession('clienteSeleccionado');
      setCliente(cliente);
      const { data } = await get(`dte/${cliente.id}/by-cliente-id`, {
        page,
        limit,
        search,
        ambiente,
        tipo: tipoDte,
        startDate,
        endDate,
      });

      if (data.results.length === 0) {
        setPage(pageDefault);
      }
      setDteByCliente(data);
    } catch (error) {
      enqueueSnackbar(
        'Se ha producido un error al momento de obtener los dte!',
        {
          variant: 'error',
          preventDuplicate: true,
        },
      );
    }
  }, [page, limit, search, ambiente, tipoDte, startDate, endDate]);

  useEffect(() => {
    searchParams.set('page', page);
    searchParams.set('limit', limit);
    setSearchParams(searchParams);
  }, [page, limit, searchParams, setSearchParams]);

  useEffect(() => {
    async function getDte() {
      await getDteByClienteId();
    }

    async function getTipoDte() {
      const clienteData = getSession('clienteSeleccionado');
      const { data } = await get(`clientes/dte/${clienteData.id}`);
      setTiposDte(data);
    }
    getDte();

    getTipoDte();
  }, [getDteByClienteId]);

  useEffect(() => {
    destroyItemSession('queryParamsConsulta');
  }, []);

  useEffect(() => {
    const timezoneName = 'America/El_Salvador';
    // Obtener el primer día del mes y el día actual en la zona horaria especificada
    const today = dayjs().tz(timezoneName);
    const firstDayOfMonth = today.startOf('month');

    // Actualizar los estados con las fechas formateadas
    setStartDate(firstDayOfMonth.format('YYYY-MM-DD'));
    setEndDate(today.format('YYYY-MM-DD'));
  }, [setStartDate, setEndDate]);

  const setPageDefault = () => {
    setPage(pageDefault);
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set('page', pageDefault);
    setSearchParams(newSearchParams);
  };

  const debouncedHandleSearch = useMemo(
    () => _debounce(setSearch, 500),
    [setSearch],
  );

  const setQueryParams = (name, value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(name, value);
    setSearchParams(newSearchParams);
    if (name === 'page') {
      setPage(value);
    } else {
      setLimit(value);
    }
  };

  const handleSearchChange = (e) => {
    setPageDefault();
    debouncedHandleSearch(e.target.value);
  };

  return (
    <div className="row gx-3">
      <div className="col-12">
        <div className="card">
          <div className="card-header border-bottom border-200 px-0">
            <div className="d-lg-flex justify-content-between">
              <div className="row flex-between-center gy-2 px-x1">
                <div className="col-auto pe-0">
                  <h6 className="mb-0">DTE emitidos</h6>
                </div>
                <div className="col-auto">
                  <form>
                    <div className="input-group">
                      <input
                        className="form-control form-control-lg"
                        type="search"
                        placeholder="Buscar..."
                        onChange={handleSearchChange}
                        aria-label="search"
                      />
                      <button className="btn btn-sm btn-outline-secondary border-300 hover-border-secondary">
                        <span className="fa fa-search fs--1" />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="border-bottom border-200 my-3" />
              <div className="d-flex align-items-center justify-content-between justify-content-lg-end px-x1">
                <div
                  className="d-flex align-items-center"
                  id="table-ticket-replace-element"
                >
                  <button
                    className="btn btn-falcon-default btn-sm mx-2"
                    type="button"
                    onClick={() => navigate('/dte')}
                  >
                    <span
                      className="fas fa-plus"
                      data-fa-transform="shrink-3"
                    />
                    <span>Emitir nuevo DTE</span>
                  </button>
                  <button
                    className="btn btn-sm btn-primary mx-2"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                  >
                    <span
                      className="fas fa-filter"
                      data-fa-transform="shrink-3"
                    />
                    <span>Mostrar Filtros</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="card-body p-0">
            <div className="table-responsive scrollbar">
              <table className="table mb-0 fs--1 table-hover table-bordered border-2">
                <thead className="text-800 bg-light">
                  <tr>
                    <th className="text-center no-wrap">OPCIONES</th>
                    <th className="text-center ps-2 no-wrap">TIPO DTE</th>
                    <th className="text-center ps-2 no-wrap">
                      NOMBRE O RAZÓN SOCIAL
                    </th>
                    <th className="text-center ps-2 no-wrap">
                      NOMBRE COMERCIAL
                    </th>
                    <th className="text-center no-wrap">MONTO</th>
                    <th className="text-center no-wrap">ESTADO</th>
                    <th className="text-center no-wrap">FECHA DE GENERACIÓN</th>
                    <th className="text-center ps-2 no-wrap">
                      TIPO DE DOCUMENTO
                    </th>
                    <th className="text-center no-wrap">NÚMERO DE DOCUMENTO</th>
                    <th className="text-center no-wrap">NÚMERO DE CONTROL</th>
                    <th className="text-center no-wrap">
                      CÓDIGO DE GENERACIÓN
                    </th>
                  </tr>
                </thead>
                <tbody className="list" id="table-ticket-body">
                  {dteByClient?.results?.length > 0
                    ? dteByClient?.results.map((dte) => (
                        <tr key={dte?._id}>
                          <td className="align-middle text-center">
                            <Action
                              dte={dte}
                              cliente={cliente}
                              modalReporteRef={modalReporteRef}
                            />
                          </td>
                          <td className="text-start no-wrap">
                            <div className="d-flex align-items-center gap-2 ">
                              <span className="fas fa-circle text-success fs--2" />
                              {getDteType(
                                dte?.datos_dte?.identificacion?.tipoDte,
                              )?.toUpperCase()}
                            </div>
                          </td>
                          <td className="no-wrap text-center">
                            {dte?.datos_dte?.receptor?.nombreComercial?.toUpperCase() ||
                              dte?.nombre_comercial_receptor?.toUpperCase() ||
                              dte?.datos_dte?.sujetoExcluido?.nombre?.toUpperCase() ||
                              'N/A'}
                          </td>

                          <td className="no-wrap">
                            {dte?.datos_dte?.receptor?.nombre?.toUpperCase() ||
                              dte?.datos_dte?.sujetoExcluido?.nombre?.toUpperCase() ||
                              'CLIENTE GENERAL'}
                          </td>

                          <td className="align-middle text-center no-wrap">
                            <div className="d-grid gap-2">
                              <span className="badge bg-primary fs--1">
                                $
                                {dte?.datos_dte?.resumen?.totalPagar
                                  ? parseFloat(
                                      dte?.datos_dte?.resumen?.totalPagar,
                                    ).toFixed(2)
                                  : parseFloat(
                                      dte?.datos_dte?.resumen
                                        ?.montoTotalOperacion,
                                    ).toFixed(2)}
                              </span>
                            </div>
                          </td>

                          <td className="align-middle text-center no-wrap">
                            <div className="d-grid gap-2">
                              {dte?.invalidado ? (
                                <span className="badge badge-subtle-danger fs--1">
                                  <span>Invalidado</span>
                                </span>
                              ) : (
                                <ValidateErrorDte dte={dte} />
                              )}
                            </div>
                          </td>

                          <td className="align-middle text-center no-wrap">
                            {getLocalDate(
                              dte.created_at,
                              'dddd DD MMMM YYYY, h:mm:ss a',
                            )}
                          </td>

                          {dte?.datos_dte?.receptor?.tipoDocumento ? (
                            <td className="align-middle text-center status fs-0 pe-4">
                              {getTipoDocumento(
                                dte?.datos_dte?.receptor?.tipoDocumento,
                              ) || 'N/A'}
                            </td>
                          ) : null}

                          {dte?.datos_dte?.sujetoExcluido?.tipoDocumento ? (
                            <td className="align-middle text-center status fs-0 pe-4">
                              {getTipoDocumento(
                                dte?.datos_dte?.sujetoExcluido?.tipoDocumento,
                              ) || 'N/A'}
                            </td>
                          ) : null}
                          {dte?.datos_dte?.receptor?.nit ? (
                            <td className="align-middle text-center status fs-0 pe-4">
                              NIT
                            </td>
                          ) : null}

                          {!dte?.datos_dte?.receptor?.tipoDocumento &&
                          !dte?.datos_dte?.sujetoExcluido?.tipoDocumento &&
                          !dte?.datos_dte?.receptor?.nit ? (
                            <td className="align-middle text-center status fs-0 pe-4">
                              N/A
                            </td>
                          ) : null}

                          {dte?.datos_dte?.receptor?.tipoDocumento ||
                          dte?.datos_dte?.sujetoExcluido?.tipoDocumento ? (
                            <td className="align-middle text-center pe-4 no-wrap">
                              {dte?.datos_dte?.receptor?.numDocumento ||
                                dte?.datos_dte?.sujetoExcluido?.numDocumento ||
                                'N/A'}
                            </td>
                          ) : null}

                          {dte?.datos_dte?.receptor?.nit ? (
                            <td className="align-middle text-center pe-4 no-wrap">
                              {dte?.datos_dte?.receptor?.nit}
                            </td>
                          ) : null}

                          {!dte?.datos_dte?.receptor?.tipoDocumento &&
                          !dte?.datos_dte?.sujetoExcluido?.tipoDocumento &&
                          !dte?.datos_dte?.receptor?.nit ? (
                            <td className="align-middle text-center status fs-0 pe-4 no-wrap">
                              N/A
                            </td>
                          ) : null}

                          <td className="align-middle text-center no-wrap">
                            {dte.numero_control}
                          </td>

                          <td className="align-middle text-center no-wrap">
                            {dte.codigo_generacion}
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
              {dteByClient?.results?.length === 0 ? (
                <div className="text-center" id="tickets-table-fallback">
                  <p className="fw-bold fs-1 mt-3">
                    No se encontraron resultados
                  </p>
                </div>
              ) : null}
            </div>
          </div>
          {dteByClient?.pageLinks?.length > 0 && (
            <div className="card-footer">
              <div className="d-flex justify-content-center">
                <button
                  className="btn btn-sm btn-falcon-default me-1"
                  type="button"
                  title="Anterior"
                  data-list-pagination="prev"
                  disabled={!dteByClient?.hasPreviousPage}
                  onClick={() =>
                    setQueryParams('page', dteByClient?.previousPage)
                  }
                >
                  <span className="fas fa-chevron-left"></span>
                </button>
                <ul className="pagination mb-0">
                  {dteByClient?.pageLinks?.map((page) =>
                    page !== '...' ? (
                      <button
                        className="btn btn-sm btn-falcon-default text-primary me-2"
                        key={page}
                        onClick={() => setQueryParams('page', page)}
                        disabled={page === dteByClient?.currentPage}
                      >
                        {page}
                      </button>
                    ) : (
                      <button
                        className="btn btn-sm btn-falcon-default me-2"
                        disabled={true}
                      >
                        <span className="fas fa-ellipsis-h" />
                      </button>
                    ),
                  )}
                </ul>
                <button
                  className="btn btn-sm btn-falcon-default ms-1"
                  type="button"
                  title="Siguiente"
                  data-list-pagination="next"
                  disabled={!dteByClient?.hasNextPage}
                  onClick={() => setQueryParams('page', dteByClient?.nextPage)}
                >
                  <span className="fas fa-chevron-right"></span>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      <div>
        <div
          className="offcanvas offcanvas-end"
          id="offcanvasRight"
          tabIndex={-1}
          aria-labelledby="offcanvasRightLabel"
        >
          <div className="offcanvas-header">
            <h5 id="offcanvasRightLabel">Búsqueda por filtros</h5>
            <button
              className="btn-close text-reset"
              type="button"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            />
          </div>
          <div className="offcanvas-body">
            <div className="row">
              <div className="col-12">
                <form>
                  <div className="mb-2 mt-n2">
                    <label className="mb-1">Ambiente:</label>
                    <select
                      className="form-select"
                      aria-label="Ambientes"
                      onChange={(e) => setAmbiente(e.target.value)}
                      value={ambiente}
                    >
                      <option key={'01'} value="01">
                        Producción
                      </option>
                      <option key={'00'} value="00">
                        Pruebas
                      </option>
                    </select>
                  </div>
                  <div className="mb-2">
                    <label className="mb-1 mt-2">Tipo DTE:</label>
                    <select
                      className="form-select"
                      aria-label="Tipos de DTE"
                      value={tipoDte}
                      onChange={(e) => setTipoDte(e.target.value)}
                    >
                      <option value={''} disabled={true}>
                        Seleccione un opción
                      </option>

                      {tiposDte?.map((tipo) => (
                        <option key={tipo.code} value={tipo.code}>
                          {tipo.value}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-2">
                    <label className="mb-1 mt-2">Fecha desde:</label>
                    <div className="mb-3">
                      <input
                        className="form-control"
                        type="date"
                        placeholder="dd/mm/yy"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="mb-2">
                    <label className="mb-1 mt-2">Fecha hasta:</label>

                    <input
                      className="form-control"
                      type="date"
                      format="dd/mm/yyyy"
                      placeholder="dd/mm/yy"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>
                </form>

                <button
                  onClick={() => {
                    const today = dayjs().tz('America/El_Salvador');
                    const firstDayOfMonth = today.startOf('month');
                    setAmbiente(ambienteDefault);
                    setTipoDte('');
                    setStartDate(firstDayOfMonth.format('YYYY-MM-DD'));
                    setEndDate(today.format('YYYY-MM-DD'));
                  }}
                  className="btn btn-primary w-100"
                >
                  Restablecer filtros
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalReporte ref={modalReporteRef} />
    </div>
  );
};

export default ConsultaV2;
