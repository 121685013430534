import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  clearSession,
  getSession,
} from '../../../services/encrytion/encryption.service';

const NavBar = () => {
  const navigate = useNavigate();

  const [alias] = React.useState(getSession('alias'));

  const logout = (e) => {
    e.preventDefault();
    clearSession();
    const scriptElem = document.getElementById('theme-script');
    scriptElem?.remove();
    navigate('/login');
  };

  return (
    <nav className="navbar navbar-light navbar-glass navbar-top navbar-expand">
      <button
        className="btn navbar-toggler-humburger-icon navbar-toggler me-1 me-sm-3"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarVerticalCollapse"
        aria-controls="navbarVerticalCollapse"
        aria-expanded="false"
        aria-label="Toggle Navigation"
      >
        <span className="navbar-toggle-icon">
          <span className="toggle-line" />
        </span>
      </button>
      <a className="navbar-brand me-1 me-sm-3" href="/">
        <img
          className="me-2"
          src="https://res.cloudinary.com/dsyq7zx0p/image/upload/v1735414109/nav_color_txu2p6.png"
          alt="..."
          width={250}
        />
      </a>

      <ul className="navbar-nav navbar-nav-icons ms-auto flex-row align-items-center">
        <li className="nav-item dropdown">
          <a
            className="nav-link pe-0 ps-2"
            id="navbarDropdownUser"
            role="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            href="#!"
          >
            <div className="avatar avatar-xl">
              <img
                className="rounded-circle"
                src={`${process.env.PUBLIC_URL}/assets/img/avatar.png`}
                alt="User"
              />
            </div>
          </a>
          <div
            className="dropdown-menu dropdown-caret dropdown-caret dropdown-menu-end py-0"
            aria-labelledby="navbarDropdownUser"
          >
            <div className="bg-white dark__bg-1000 rounded-2 py-2">
              <a className="dropdown-item fw-bold text-warning" href="#!">
                <span>{alias}</span>
              </a>
              <div className="dropdown-divider" />

              <a
                className="dropdown-item"
                href="!#"
                onClick={logout.bind(this)}
              >
                Cerrar sesión
              </a>
            </div>
          </div>
        </li>
      </ul>
    </nav>
  );
};

export default NavBar;
